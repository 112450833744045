<template>
    <v-main>
        <Header/>
        <div class="section6">

      <v-row>
                <v-col>
                    <div class="aurora2">

        <v-img max-height="30em" min-height="20em" contain :src="require('../assets/Resized Pictures/_DSC4889.webp')">
         <!-- <v-card-title    style="margin-top:5em;"  primary-title>
             <h1>
             Digital Marketing
             </h1>
         </v-card-title> -->
         </v-img> 

             </div>
                </v-col>
            </v-row>
        <v-container>
           <v-row>
            <v-card-title style="margin-bottom:1em" primary-title>  
                  <h2>
                      Digital Marketing
                  </h2>
            <br>
            </v-card-title>
                   <div class="space">
               <v-col>
                   <h2>
                       Ignoring online marketing is like opening a business but not telling anyone.
                   </h2>
                       <br>

        <p>The number of online users has increased from a few hundred to millions in just about a decade.
             At the same time, the internet has become a crowded place with multiple websites calling for customers’
              attention simultaneously. You have to stand apart from your competitors and steal the spotlight. But, how do you ensure that everyone takes notice of you?
           </p>
               </v-col>
                   </div>
           </v-row>
        </v-container>
        <v-container>
            <v-row>
                <v-col>

        <h3> 
         A combination of web marketing services, like the following from The Coding Effect, can help your business achieve extraordinary growth:
        </h3>
                </v-col>
            </v-row>
        </v-container>



        <MarketingCards/>
       
       
       
       
       
        <v-container>
            <v-row>
              <div class="space">
                <v-col>
                    <p>Upgrading to having your own business stand-alone system will give you the privilege of automation of the organisation's tasks as well as reporting the progress or lags in the organisation's activities. This improves company's efficiency and effectiveness of the company's activities. The software reduces the workload and automation of activities.</p>
                </v-col>
              </div>
            </v-row>

            <!-- customer benifits -->
 
             
            <v-row class="justify-center">
                    <!-- <v-col>
                        <div class="round">

                        <v-img v-tilt="{glare:true, reverse: true}" :src="require('../assets/samuel-ferrara-uOi3lg8fGl4-unsplash.jpg')" height="200px" width="200px"></v-img>
                        </div>
                        <div class="round">
                            <v-img v-tilt="{glare:true, reverse: true}" :src="require('../assets/fish Website Pictures/caspar-camille-rubin-89xuP-XmyrA-unsplash.jpg')" height="300px" width="200px"></v-img>
                        </div>
                    </v-col> -->
                        <v-card-title  style="word-break: normal; margin-top:1.5em;">
                    <h2>
                    With Digital Marketing we'll ensure you:
                    </h2>
                    </v-card-title> 
                <v-col cols="12" lg="6" md="8" sm="8">
                <!-- <div class="space" stylße="background-color:rgba(0,0,255,0.02);"> -->
                <div class="aurora2">
                    <v-card-text  >
            
                        <ul >
                            <li>
                               <p>
                                    Target The Right Audience 
                                   </p>
                                    </li>
                        <li>
                            <p>
                       Get more retain for your investment
                            </p>
                        </li>
                        <li>
                              <p>
                         Establish trust with yout Audience
                          </p>
                        </li>
                        <li>
                              <p>
                         Create brand awareness
                            </p>
                            </li>
                        <li>
                              <p>
                       Level the playing field
                         </p>
                            </li>
                        </ul>
                        </v-card-text>   
                </div>
                <!-- </div> -->
                    </v-col>
            </v-row>
                <!-- </div> -->
        </v-container>
        <v-container>
            <v-row>
                <v-col>

            <v-lazy>

        <v-container>
            <v-row>
                <v-col>
                    <h1>
                     We Are Experienced marketing on:
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col  lg="4" md="3" sm="4"  v-for="item in items" :key="item.id">
                    <div class="space" v-tilt="{glare:true, reverse: true}">
                        <v-lazy>

                    <v-img class="icon" :max-height="height" :max-width="width" contain :src='item.icon'>
                        </v-img>
                        </v-lazy>
                    </div>
                </v-col>
            </v-row>
        </v-container>
            </v-lazy>
                </v-col>
                <v-col>

        <v-container>
            <v-row>
                <v-col>
                    <h1>
                        We're experienced in building:
                    </h1>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            
        <!-- </v-container> -->
            <v-row>
                    <div class="space"  >
                <v-col>
                        <ul>
                            <li>
                              <p>
                                Branding
                                </p>
                            </li>
                             <li>
                                <p>

                                     Social Media Marketing
                                </p>
                              
                            </li>
                            <li>
                                <p>
                                Graphic Design
                                </p>

                            </li>
                            <li>
                                <p>
                                    Ecommerce SEO Services
                                </p>
                                
                            </li>
                            <li>
                                <p>
                                    Ad Copy Writing
                                </p>
                                
                            </li>
                            <li>
                                <p>
                                     Pay-Per-Click Management
                                </p>
                               
                            </li>
                            <li>
                                <p>
                                    Influencer Marketing
                                </p>
                               
                            </li>
                            <li>
                                <p>
                                     Content Creation
                                </p>
                               
                            </li>                               
                        </ul>
                </v-col>
                    </div>
            </v-row>
        </v-container>

                </v-col>
    </v-row>
        </v-container>



         <Footer/>   
        </div>

    </v-main>
</template>
<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
import MarketingCards from '../components/MarketingCards.vue'


export default {
  components: {  Header,Footer, MarketingCards },
    name: 'System',
    data() {
        return {
            items:[
          {
            id:1,
            icon: require('../assets/icons/001-facebook.svg'),
            // color:'orange darken-2'
          },
          {
            id:2,
            icon: require('../assets/icons/002-youtube.svg'),
            color:'blue-grey darken-2'
          },
          {
            id:3,
            icon: require('../assets/icons/003-twitter.svg'),
            color:'orange darken-2'
          },
          {
            id:4,
            icon: require('../assets/icons/004-linkedin.svg'),
            color:'teal'
          },
        //   {
        //     id:5,
        //     icon: require('../assets/icons/.svg'),
        //     color:'primary'
        //   },
        //   {
        //     id:6,
        //     icon: require('../assets/icons/Vue.svg'),
        //     color:'primary'
        //   },
        //   {
        //     id:7,
        //     icon: require('../assets/icons/vuetifyjs.svg'),
        //     color:'primary'
        //   },
        //   {
        //     id:8,
        //     icon: require('../assets/icons/bootstrap.svg'),
        //     color:'primary'
        //   }
        ]
            
        }
    },
    computed: {
         height() {
        if (this.$vuetify.breakpoint.name >='xs'){
            return '5em'
        }else{
            return 'auto'
        }
    },
    width() {
        if (this.$vuetify.breakpoint.name >= 'xs'){
            return '5em'
        }else{
            return '8em'
        }
    },
 },
 metaInfo: {
   
   title: 'Digital Marketing Services',
    
   meta: [{
        vmid: 'description',
        name: 'description',
      content: 'Digital Marketing Company in Sudan. The Code Effect offers a variety of online marketing and Graphic Design Services that make you stand apart. Branding, SEO, Mobile Marketing, Logo, and Social Media Marketing.'
      }]
    },
    
}
</script>
<style lang="postcss">
/* List */
 @import '../sass/list.sass'
</style>