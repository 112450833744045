<template>
    <v-main>
         <v-container>
  
                <v-row>

                   <v-col lg="5" md="6" sm="12" v-for="item in items" :key="item.id">
                     <v-lazy transition="scroll-x-transition">

     <div class="space">
                         <v-container >
                          <!-- <div class="cards"> -->
                            <v-card color="rgba(0,0,0,0)">
                              

                     <!-- <v-icon :color="item.color"> -->
                        <v-img class="icon" contain height="auto" width="auto" max-height="7em" max-width="3em" :src='item.icon'>
                        </v-img>
                        <!-- </v-icon> -->
                     
                     <v-card-title style="word-break: normal;">
                       <h3>
                         {{item.title}}

                       </h3>
                     </v-card-title>
                     <v-card-text >
                       <p>

                         {{item.p}}
                       </p>
                     </v-card-text>
                          <!-- </div> -->
                            </v-card>
                      </v-container>
                  
         </div>
                     </v-lazy>
         </v-col>
             
        </v-row>
        </v-container>
    </v-main>

</template>
<script>
export default {
    name:'MarketingCards',
    data() {
        return {
             items:[
          {
            id:1,
            icon: require('../assets/icons/services/seo.svg'),
            title: "SEO / SEM"  ,
            p: 'When people have a question they turn to Google. So, let’s get you ranking where it matters most. SEO enables you to connect with Google users when they are searching for the products or services you offer, but don’t know who you are. With extensive research, tailored keywords and call-to-action ads, you will be the company that customers connect with.'
          },
          {
            id:2,
            icon: require('../assets/icons/services/009-email-1.svg'),
             title: 'Email Marketing' ,
            p:'Email marketing still works wonders, as long as campaigns are managed with great skill and the content is truly useful to subscribers. Two big advantages of email: everybody uses it, and campaigns can accomplish a wide range of marketing goals — lead generation, online revenue, brand building, customer retention, customer engagement, and much more.'
          },
          {
            id:3,
            icon: require('../assets/icons/services/content.svg'),
            title: 'Content Marketing' ,
            p:'Your content needs to carry a unique and identifiable voice, style, and pitch across all distribution channels. With content marketing, you can build trust with your audience, improve conversions, connect with your customers, and generate leads.'
          },
          {
            id:4,
            icon: require('../assets/icons/services/socialmedia.svg'),
            title: 'Social Media Marketing' ,
            p:'No business is alike, which is why we offer custom social media management services. With a custom social media marketing service plan, your business can start building brand awareness, as well as generating revenue from social media platforms like Facebook, Twitter, Instagram, Booking.com, AirBnb, and more.'
          }
             ]
        }
    },
}
</script>